import React from "react";
import SEO from "../components/seo";
import "../styles/normalize.css";
import "../styles/main.css";
import appstore from "../images/appstore.png";
import landingLogo from "../images/landing-logo.png";
import vybesVideo from "../images/vybes.mp4";
import playstore from "../images/playstore.png";
import { graphql, Link, useStaticQuery } from "gatsby";

export default function Home({ data }) {
  return (
    <div class="landing">
      <SEO title="Hello Music lover!" />

      <div>
        <video autoPlay muted loop>
          <source src={vybesVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div>
        <div class="pitch">
          <img src={landingLogo} alt="Vybes logo" height="140" />
          <h1>Socially inspired music</h1>
          <p>
            Share what you listen on <strong>Spotify</strong> with the press of
            a button. Inspire and be inspired by your buddies.
          </p>

          <div class="buttons">
            <a href="https://play.google.com/store/apps/details?id=io.vybes">
              <img src={playstore} alt="Playstore button" height="56" />
            </a>
            <a href="https://apps.apple.com/us/app/vybes-music/id1531124533">
              <img src={appstore} alt="Appstore button" height="56" />
            </a>
          </div>

          <div class="links">
            <Link to="/legal/privacy">Privacy policy</Link>
            <Link to="/legal/terms">Terms & Conditions</Link>
            <a href="mailto:support@vybes.io" target="_blank" rel="noreferrer">
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
